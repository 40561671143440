import {
  SENDING_INDIVIDUAL_NOTIFICATION,
  SENT_INDIVIDUAL_NOTIFICATION,
  SAVING_NOTIFICATION_SETTINGS,
  SAVED_NOTIFICATION_SETTINGS,
  FETCHING_NOTIFICATION_SETTINGS,
  FETCHED_NOTIFICATION_SETTINGS,
  FETCHING_NOTIFIER,
  FETCHED_NOTIFIER,
} from "../../constant/actionTypes";
import service from "../../utils/customServices/dashboardManagement";
import innovativeService from "../../utils/customServices/innovative"
import { showNotification } from "../../utils/general";
import _get from "lodash/get";

// Send Individual Notification
const sendingIndividualNotification = () => ({
  type: SENDING_INDIVIDUAL_NOTIFICATION,
  payload: { isLoading: true },
});

const sentIndividualNotification = (data) => {
  const { error, response } = data;
  const status = _get(response, "data.status");
  const statusMessage = _get(response, "data.statusText", "");

  if (status === 200) {
    showNotification({
      message: "Success",
      description: statusMessage,
    });
    return {
      type: SENT_INDIVIDUAL_NOTIFICATION,
      payload: {
        isLoading: false,
        error,
        data: response?.data,
      },
    };
  }

  if (error) {
    showNotification({
      message: "Error",
      description: error,
    });
    return {
      type: SENT_INDIVIDUAL_NOTIFICATION,
      payload: {
        isLoading: false,
        error,
        data: response?.data,
      },
    };
  }

  showNotification({
    message: "Unknown",
    description: "An unexpected issue occurred.",
  });
  return {
    type: SENT_INDIVIDUAL_NOTIFICATION,
    payload: {
      isLoading: false,
      error: "Unexpected error",
      data: null,
    },
  };
};

export const sendIndividualNotification = (payload) => async (dispatch) => {
  dispatch(sendingIndividualNotification());

  const requestOptions = {
    service: "insights/notificationManage/sendIndividualNotification",
    method: "POST",
    authorized: true,
    body: JSON.stringify(payload),
  };

  try {
    const response = await innovativeService.make(requestOptions);
    dispatch(sentIndividualNotification({ response }));
  } catch (error) {
    dispatch(sentIndividualNotification({ error: error.message }));
  }
};

// Save Notification Settings
const savingNotificationSettings = () => ({
  type: SAVING_NOTIFICATION_SETTINGS,
  payload: { isLoading: true },
});

const savedNotificationSettings = (data) => {
  const { error, response } = data;
  const status = _get(response, "data.status");

  if (status === 200) {
    showNotification({
      message: "Success",
      description: "Notification settings saved successfully",
    });
    return {
      type: SAVED_NOTIFICATION_SETTINGS,
      payload: {
        isLoading: false,
        error,
        data: response?.data,
      },
    };
  }

  if (error) {
    showNotification({
      message: "Error",
      description: error,
    });
    return {
      type: SAVED_NOTIFICATION_SETTINGS,
      payload: {
        isLoading: false,
        error,
        data: response?.data,
      },
    };
  }

  showNotification({
    message: "Unknown",
    description: "An unexpected issue occurred.",
  });
  return {
    type: SENT_INDIVIDUAL_NOTIFICATION,
    payload: {
      isLoading: false,
      error: "Unexpected error",
      data: null,
    },
  };
};

export const saveNotificationSettings = (payload) => async (dispatch) => {
  dispatch(savingNotificationSettings());

  const requestOptions = {
    service: "insights/notificationManage/saveNotificationSettings",
    method: "POST",
    authorized: true,
    body: JSON.stringify(payload),
  };

  try {
    const response = await service.make(requestOptions);
    dispatch(savedNotificationSettings({ response }));
  } catch (error) {
    dispatch(savedNotificationSettings({ error: error.message }));
  }
};

// Fetch Notification Settings
const fetchingNotificationSettings = () => ({
  type: FETCHING_NOTIFICATION_SETTINGS,
  payload: { isLoading: true },
});

const fetchedNotificationSettings = (data) => {
  const { error, response } = data;
  if (error) {
    showNotification({
      message: "Error",
      description: error,
    });
  }
  return {
    type: FETCHED_NOTIFICATION_SETTINGS,
    payload: {
      isLoading: false,
      error,
      data: response?.data,
    },
  };
};

export const fetchNotificationSettings = () => async (dispatch) => {
  dispatch(fetchingNotificationSettings());

  const requestOptions = {
    service: "insights/notificationManage/fetchNotificationSettings",
    method: "POST",
    authorized: true,
  };

  try {
    const response = await service.make(requestOptions);
    dispatch(fetchedNotificationSettings({ response }));
  } catch (error) {
    dispatch(fetchedNotificationSettings({ error: error.message }));
  }
};

const fetchingNotifier = () => ({
  type: FETCHING_NOTIFIER,
  payload: { isLoading: true },
});

const fetchedNotifier = (data) => {
  const { error, response } = data;
  return {
    type: FETCHED_NOTIFIER,
    payload: {
      isLoading: false,
      error,
      data: response?.data,
    },
  };
};

export const fetchNotifier = (payload) => async (dispatch) => {
  dispatch(fetchingNotifier());

  const requestOptions = {
    service: "insights/notificationManage/fetchNotifier",
    method: "POST",
    authorized: true,
    body: JSON.stringify(payload),
  };

  try {
    const response = await service.make(requestOptions);
    dispatch(fetchedNotifier({ response }));
  } catch (error) {
    dispatch(fetchedNotifier({ error: error.message }));
  }
};