import React, { useState, useEffect } from "react";
import { Switch, Card, Row, Col, Button, Select, Typography, Form, Modal, Divider } from "antd";
import { useDispatch, useSelector } from "react-redux";
import NotificationMessageComponent from "../../common/notificationManagement/notificationMessageComponent";
import {
  setBreadcrumb,
  setMenuSelected,
} from "../../../actions/dashboardManagement/layout.action";
import menuData from "../../layout/dashboardManagement/menuData";
import {
  fetchNotificationSettings,
  saveNotificationSettings,
} from "../../../actions/notificationManagement/notification.action";
import {
  getFetchedNotificationSettingsData,
  getFetchedNotificationSettingsLoading,
  getFetchedNotifier,
  getFetchedNotifierLoading,
} from "../../../selectors/notificationManagement/notificationManagement.selector";
import { ExclamationCircleTwoTone } from "@ant-design/icons";
import Loader from "../common/layout/Loader";
import DateTimePicker from "../../common/notificationManagement/custom-date-time-picker/dateTimePicker";
import NotifierSearch from "../../common/notificationManagement/notifierSearch/notifierSearch";
import RoleSelect from "../../common/notificationManagement/roleSelect/roleSelect";
import { SAVE_TIMEOUT } from "../../../constant/innovative/common";

const { Title } = Typography;

const NotificationManagement = () => {
  const dispatch = useDispatch();

  const [inactivityNotificationsEnabled, setInactivityNotificationsEnabled] =
    useState(true);
  const [
    productAndFeatureNotificationsEnabled,
    setProductAndFeatureNotificationsEnabled,
  ] = useState(true);
  const [reminderFrequency, setReminderFrequency] = useState({});
  const [reminderRoles, setReminderRoles] = useState([]);
  const [sub, setSub] = useState();
  const [message, setMessage] = useState();
  const [date, setDate] = useState();
  const [errors, setErrors] = useState({ subject: "", message: "" });
  const [selectedValue, setSelectedValue] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const fetchedNotificationSettingsData = useSelector(
    getFetchedNotificationSettingsData
  );
  const fetchedNotificationSettingsLoading = useSelector(
    getFetchedNotificationSettingsLoading
  );
  const notifierData = useSelector(getFetchedNotifier);
  const notifierLoading = useSelector(getFetchedNotifierLoading);

  const remainderDates = [
    { value: "two-week", label: "Every 2 weeks" },
    { value: "month", label: "Monthly Basis" },
    { value: "three-months", label: "Quarterly Basis" },
    { value: "year", label: "Yearly Basis" },
  ];

  const breadcrumbItems = [
    {
      key: "admin",
      section: "Admin",
    },
    {
      key: menuData.notificationManagement.key,
      section: menuData.notificationManagement.name,
    },
  ];

  const checkTimeDifference = (dateString) => {
    const inputDate = new Date(dateString);
    const now = new Date();
    const timeDifference = now - inputDate;
    return timeDifference >= SAVE_TIMEOUT;
  };

  const validateInputs = () => {
    const newErrors = { subject: "", message: "", date: "" };
    let isValid = true;

    if (!sub) {
      newErrors.subject = "* Subject is required";
      isValid = false;
    }
    if (!message) {
      newErrors.message = "* Message is required";
      isValid = false;
    }
    if (!date) {
      newErrors.date = "* Start Date/Time is required";
      isValid = false;
    }
    if (!Object.keys(selectedValue).length) {
      newErrors.selectedValue = "* Notifier is required";
      isValid = false;
    }
    if (!reminderRoles.length) {
      newErrors.reminderRoles = "* Role is required";
      isValid = false;
    }
    if (!reminderFrequency) {
      newErrors.reminderFrequency = "* Reminder Frequency is required";
      isValid = false;
    }
    if (fetchedNotificationSettingsData.date_time != date) {
      if (date && checkTimeDifference(date)) {
        newErrors.reminderTimeSettings = "* Start Date/Time needs to be 5 minutes ahead of the current time";
        isValid = false;
      }
    }

    setErrors(newErrors);
    return isValid;
  };

  const sendInduvidualMessage = async () => {
    const payload = {
      inactivity_alert: inactivityNotificationsEnabled,
      send_remainder: reminderFrequency?.value,
      subject: sub,
      message: message,
      release_alert: productAndFeatureNotificationsEnabled,
      date_time: date,
      reminder_groups: reminderRoles,
      notifierName: selectedValue.value,
      notifierUserId: selectedValue.userId,
      first_notification:
        fetchedNotificationSettingsData.date_time == date
          ? fetchedNotificationSettingsData.first_notification
          : true,
    };
    await dispatch(saveNotificationSettings(payload));
  };

  useEffect(() => {
    dispatch(fetchNotificationSettings());
    dispatch(setBreadcrumb(breadcrumbItems));
  }, []);

  useEffect(() => {
    setInactivityNotificationsEnabled(
      fetchedNotificationSettingsData.inactivity_alert
    );
    setProductAndFeatureNotificationsEnabled(
      fetchedNotificationSettingsData.release_alert
    );
    const setReminder = {
      value: fetchedNotificationSettingsData.send_remainder,
      label: fetchedNotificationSettingsData.send_remainder,
    };
    setReminderFrequency(setReminder);
  }, [fetchedNotificationSettingsData]);

  // set menu item
  useEffect(() => {
    dispatch(setMenuSelected(menuData.notificationManagement.key));
  }, []);

  const onInactivityNotificationsToggle = (checked) => {
    setInactivityNotificationsEnabled(checked);
  };

  const onReminderFrequencyChange = (_, frequent) => {
    setReminderFrequency(frequent);
  };

  const rolesChange = (value) => {
    setReminderRoles(value);
  };

  const onDateChange = (date) => {
    setDate(date);
  };

  const setSelect = (value) => {
    setSelectedValue(value);
  };

  const showModal = () => {
    if (validateInputs()) {
      setIsModalVisible(true);
    }
  };

  const handleOk = async () => {
    await sendInduvidualMessage();
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const cancelConfig = () => {
    dispatch(fetchNotificationSettings());
  };

  const renderInactivityNotificationsContent = () => {
    if (inactivityNotificationsEnabled) {
      return (
        <div>
          <Row gutter={[8, 4]}>
            <Divider className="notification-management-divider" />
            <Col span={12}>
              <Form.Item
                colon={false}
                label="Start Date/Time"
                labelAlign="left"
              >
                <DateTimePicker
                  input={fetchedNotificationSettingsData.date_time}
                  date={onDateChange}
                />
                {errors.date && (
                  <div className="notification-form-validation-text">{errors.date}</div>
                )}
                {errors.reminderTimeSettings && (
                  <div className="notification-form-validation-text">
                    {errors.reminderTimeSettings}
                  </div>
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                colon={false}
                label="Send Reminder in"
                labelAlign="left"
              >
                <Select
                  value={reminderFrequency?.value}
                  className="notification-message-frequency"
                  onChange={onReminderFrequencyChange}
                  options={remainderDates}
                />
                {errors.reminderFrequency && (
                  <div className="notification-form-validation-text">{errors.reminderFrequency}</div>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[8, 4]}>
            <Divider className="notification-management-divider" />
            <Col span={12}>
              <Form.Item colon={false} label="Notifier" labelAlign="left">
                <NotifierSearch
                  isSearchLoading={notifierLoading}
                  notifierData={notifierData}
                  savedNotifierId={
                    fetchedNotificationSettingsData.notifierUserId
                  }
                  savedNotifierName={
                    fetchedNotificationSettingsData.notifierName
                  }
                  setSelectedNotifier={setSelect}
                />
                {errors.selectedValue && (
                  <div className="notification-form-validation-text">{errors.selectedValue}</div>
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item colon={false} label="Role" labelAlign="left">
                <RoleSelect
                  rolesChange={rolesChange}
                  savedRoles={fetchedNotificationSettingsData.reminder_groups}
                />
                <div className="notification-form-info-icon-text-container">
                  <ExclamationCircleTwoTone className="notification-form-info-icon" />
                  <span className="notification-form-info-text">
                    This inactivity notification will be sent to the designated
                    role type.
                  </span>
                </div>
                {errors.reminderRoles && (
                  <div className="notification-form-validation-text">{errors.reminderRoles}</div>
                )}
              </Form.Item>
            </Col>
            <Divider className="notification-management-divider" />
          </Row>
          <NotificationMessageComponent
            sub={fetchedNotificationSettingsData.subject}
            message={fetchedNotificationSettingsData.message}
            setMessage={setMessage}
            setSub={setSub}
            errors={errors}
          />
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <Loader isLoading={fetchedNotificationSettingsLoading} />
      <div className="student-cluster-settings in-container">
        <div>
          <Title level={4}>Notification Management</Title>
        </div>
        <div>
          <Card className="switch-card">
            <Row
              className="notification-card-row"
              justify="space-between"
            >
              <Col span={20}>
                <Title level={5}>Inactivity Notifications</Title>
              </Col>
              <Col span={4}>
                <Row justify="end">
                  <Switch
                    checked={inactivityNotificationsEnabled}
                    onChange={onInactivityNotificationsToggle}
                  />
                </Row>
              </Col>
            </Row>
            {renderInactivityNotificationsContent()}
          </Card>
        </div>
        <div>
          <Card className="switch-card" >
            <Row
              className="notification-card-row"
              justify="space-between"
            >
              <Col span={20}>
                <Title level={5}>
                  Notifications for product and feature releases
                </Title>
              </Col>
              <Col span={4}>
                <Row justify="end">
                  <Switch
                    checked={productAndFeatureNotificationsEnabled}
                    onChange={setProductAndFeatureNotificationsEnabled}
                    disabled
                  />
                </Row>
              </Col>
            </Row>
          </Card>
        </div>
        <Card className="switch-card">
          <Row justify="end">
            <Button onClick={cancelConfig} style={{ marginRight: "8px" }}>
              Cancel
            </Button>
            <Button onClick={showModal} type="primary">
              Save
            </Button>
            <Modal
              title="Please Confirm"
              visible={isModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}
              okText="Confirm"
              cancelText="Cancel"
            >
              <p>
                Once confirmed, the scheduled setup will trigger a notification
                message to be sent to the inactive user’s Canvas inbox according
                to the set frequency.
              </p>
            </Modal>
          </Row>
        </Card>
      </div>
    </>
  );
};

export default NotificationManagement;
