import React, { useEffect, useState } from "react";
import { Layout, Button } from "antd";
import { MenuOutlined } from '@ant-design/icons';
import logo from '../../../assets/images/sqLogo.png';
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useHistory, useLocation } from "react-router";

import Sidebar from "../../dashboardManagement/common/layout/Sidebar";
import Breadcrumb from "../../dashboardManagement/common/layout/Breadcrumb";
import { onError } from "../../../utils/notificationHandler";

import './main.css';
import "../../../assets/css/dashboard-management/main.css";
import session from "../../../utils/session";
import { queryStringToObject } from "../../../utils/general";
import menuData from "./menuData";

import { getBaseInfo, getSelectedDashboard } from "../../../selectors/dashboardManagement/dashboard.selector";
import { useWebSocket } from "../../../utils/innovative/useWebSocket";
import { fetchCourse } from "../../../actions/dashboardManagement/dashboard.action";
import { get as _get} from "lodash";

const FALSE = "false";

const DashboardManagementLayout = ({ type, children }) => {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const [isSidebarCollapsed, setIsSidebarCollapsed] = React.useState(false)
    const [tenetItemList, setTenetItemList] = useState([]);
    const urlPathName = window.location.href;
    const isSettingsPage = urlPathName?.includes('/tenant-admin/settings');
    const isSalesAdmin = urlPathName?.includes('/sales-admin');
    const isSalesAdminHome = urlPathName?.includes('/sales-admin/admin/manage');
    const isTenantAdminHome = urlPathName?.includes('/tenant-admin/dashboard/manage');
    const dashboardManagementData = session.get(_get(session, "keys.dashboardManageMeta", ""));
    const isCustomDashboard =  _get(dashboardManagementData, "custom", false);

    // selectors
    const selectedDashboard = useSelector(getSelectedDashboard);
    const baseInfo = useSelector(getBaseInfo);

    // store initial query params for future usage like auth token in actions
    useEffect(() => {
        session.setOrAppend(session.keys.dashboardManageMeta, { ...queryStringToObject(location.search), is_admin: true });
        session.setOrAppend(session.keys.impressionMeta, queryStringToObject(location.search));
    }, [location.search]);

    useEffect(() => {
        if(!isSalesAdminHome){
            dispatch(fetchCourse());
        }
    }, []);

    const getToken = () => {
        const urlToken = queryStringToObject(location.search).token;
        if (urlToken) {
            return urlToken
        } else {
            return session.get([
                session.keys.dashboardManageMeta,
                "token"
            ])
        }
    }

    const getStatUrl = (url) => {
        const token = getToken();
        if (!token) {
            return onError("Invalid Token");
        }
        return `${url}/${token}`;
    }

    const chartDataWebSocketConnection = useWebSocket({
        serverUrl: `${process.env.REACT_APP_SOCKET_CONNECTION_URL}`,
        initSendPayload: {
            action: "sendMessage",
            platform: "canvas",
        }
    });

    useEffect(() => {
        if (baseInfo?.school_id) {
            chartDataWebSocketConnection.createConnection({
                school_id: baseInfo.school_id,
                user_id: baseInfo?.user_id
            });
        }
    }, [baseInfo])

    const {
        manageDashboard,
        stats,
        statsDataView,
        globalConfigurations,
        markingScheme,
        conditionalColors,
        colorScheme,
        credentialManagement,
        adminCenter,
        manageDataSource,
        manageUser,
        userOverview,
        userImport,
        userLiveConnect,
        manageTenantAdmin,
        dashboardConfigurations,
        shareOptions,
        dataSourceConfig,
        studentClusterAnalysis,
        dataPrivacyManagement,
        notificationManagement,
    } = menuData;

    useEffect(() => {
        getTenantItemsArray(isCustomDashboard);
    }, [isCustomDashboard]);

    const getTenantItemsArray = isCustomDashboard => {
        const itemArray = [{
            name: manageDashboard.name,
            key: manageDashboard.key,
            icon: manageDashboard.icon,
            click: () => history.push(manageDashboard.url),
        },
        {
            name: stats.name,
            key: stats.key,
            icon: stats.icon,
            click: () => history.push(getStatUrl(statsDataView.url))
        },
        ];

        const insightsItems = [
            {
                name: globalConfigurations.name,
                key: globalConfigurations.key,
                icon: globalConfigurations.icon,
                subItems: [
                    {
                        name: markingScheme.name,
                        key: markingScheme.key,
                        click: () => history.push(markingScheme.url)
                    },
                    {
                        name: colorScheme.name,
                        key: colorScheme.key,
                        click: () => history.push(colorScheme.url)
                    },
                    {
                        name: conditionalColors.name,
                        key: conditionalColors.key,
                        click: () => history.push(conditionalColors.url)
                    },
                ]
            },
            {
                name: studentClusterAnalysis.name,
                key: studentClusterAnalysis.key,
                icon: studentClusterAnalysis.icon,
                click: () => history.push(studentClusterAnalysis.url),
            },
            {
                name: manageUser.name,
                key: manageUser.key,
                icon: manageUser.icon,
                click: () => history.push(manageUser.url),
            },
            {
                name: credentialManagement.name,
                key: credentialManagement.key,
                icon: credentialManagement.icon,
                rotateIcon: credentialManagement.rotateIcon,
                rotate: credentialManagement.rotate,
                click: () => history.push(credentialManagement.url),
            },
            {
                name: dataPrivacyManagement.name,
                key: dataPrivacyManagement.key,
                icon: dataPrivacyManagement.icon,
                click: () => history.push(dataPrivacyManagement.url),
            },
            {
                name: notificationManagement.name,
                key: notificationManagement.key,
                icon: notificationManagement.icon,
                click: () => history.push(notificationManagement.url),
            },
            // TODO: Uncomment this in once the components are completed
            // {
            //     name: adminCenter.name,
            //     key: adminCenter.key,
            //     icon: adminCenter.icon,
            //     click: () => history.push(adminCenter.url),
            //     subItems: [
            //         {
            //             name: manageDataSource.name,
            //             key: manageDataSource.key,
            //             click: () => history.push(manageDataSource.url)
            //         },
            //         {
            //             name: manageUser.name,
            //             key: manageUser.key,
            //             click: () => history.push(manageUser.url),
            //             subItems: [
            //                 {
            //                     name: userOverview.name,
            //                     key: userOverview.key,
            //                     click: () => history.push(userOverview.url)
            //                 },
            //                 {
            //                     name: userImport.name,
            //                     key: userImport.key,
            //                     click: () => history.push(userImport.url)
            //                 },
            //                 {
            //                     name: userLiveConnect.name,
            //                     key: userLiveConnect.key,
            //                     click: () => history.push(userLiveConnect.url)
            //                 }
            //             ]
            //         }
            //     ]
            // },
        ];
        if (
            isCustomDashboard === FALSE ||
            !isCustomDashboard
        ) {
            insightsItems.map(item => itemArray.push(item));
        }
        setTenetItemList(itemArray);
    };

    const sidebarItems = [
        {
            type: "tenant",
            items: tenetItemList,
        },
        {
            type: "sales",
            items: [
                {
                    name: manageTenantAdmin.name,
                    key: manageTenantAdmin.key,
                    icon: manageTenantAdmin.icon,
                    click: () => history.push(manageTenantAdmin.url)
                },
            ]
        },
        {
            type: "configurations",
            items: [
                // TODO: Uncomment this in once the components are completed
                // {
                //     name: dashboardConfigurations.name,
                //     key: dashboardConfigurations.key,
                //     icon: dashboardConfigurations.icon,
                //     click: () => history.push(`${dashboardConfigurations.url}/${selectedDashboard}`)
                // },
                {
                    name: shareOptions.name,
                    key: shareOptions.key,
                    icon: shareOptions.icon,
                    click: () => history.push(`${shareOptions.url}/${selectedDashboard}`)
                },
                // {
                //     name: dataSourceConfig.name,
                //     key: dataSourceConfig.key,
                //     icon: dataSourceConfig.icon,
                //     click: () => history.push(`${dataSourceConfig.url}/${selectedDashboard}`)
                // },
            ]
        }

    ]

    return (
        <Layout>
            <Layout.Header className="in-header withMenu" style={{ background: "white" }}>
                <div className="mobileNav">
                    <MenuOutlined onClick={() => setIsSidebarCollapsed(!isSidebarCollapsed)} />
                </div>
                <div className="logo">
                    <img src={logo} alt="headerLogo" />
                </div>

                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", height: "100%" }}>
                    <Breadcrumb type={type} hideBackButton={isSalesAdminHome || isTenantAdminHome} />
                    {isSalesAdmin &&
                        <Link to={"/setup/consumer"}><Button className="consumerButton">Back to Consumers</Button></Link>
                    }
                </div>


            </Layout.Header>
            <Layout className="in-sidebarLayout">
                <Sidebar
                    collapsed={isSidebarCollapsed}
                    setCollapsed={setIsSidebarCollapsed}
                    sidebarItems={isSettingsPage ? sidebarItems.find(item => item.type === "configurations").items : sidebarItems.find(item => item.type === type).items}
                />
                <Layout.Content className="dm-content">
                    {children}
                </Layout.Content>
            </Layout>
        </Layout>
    );
};

DashboardManagementLayout.defaultProps = {
    type: "tenant"
}

DashboardManagementLayout.propTypes = {
    type: PropTypes.string
}

export default DashboardManagementLayout;