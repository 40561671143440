import React, { useState, useEffect } from "react";
import { Select } from "antd";
import { fetchNotifier } from "../../../../actions/notificationManagement/notification.action";
import { useDispatch } from "react-redux";
import { SEARCH_LENGTH } from "../../../../constant/innovative/common";

const NotifierSearch = ({
  isSearchLoading,
  notifierData,
  savedNotifierId,
  savedNotifierName,
  setSelectedNotifier,
}) => {
  const dispatch = useDispatch();

  const [searchedNotifier, setSearchedNotifier] = useState([]);

  useEffect(() => {
    if (savedNotifierName && savedNotifierId) {
      const notifier = {
        value: savedNotifierName,
        userId: savedNotifierId,
      };
      setSelectedNotifier(notifier);
    }
  }, [savedNotifierName, savedNotifierId]);

  useEffect(() => {
    setSearchedNotifier(savedNotifierName);
  }, [savedNotifierName]);

  const handleSearch = (value, option) => {
    if (value && value.length > SEARCH_LENGTH) {
      const payload = {
        searchValue: value,
      };
      dispatch(fetchNotifier(payload));
    }
  };

  const renderSearchResultArray = () => {
    return notifierData.map((item) => ({
      value: item.name,
      userId: item.userId,
    }));
  };

  const handleSelection = (value, options) => {
    setSelectedNotifier(options);
    setSearchedNotifier(value);
  };

  return (
    <Select
      showSearch
      value={searchedNotifier}
      loading={isSearchLoading}
      placeholder={"Search student"}
      onChange={handleSelection}
      onSearch={handleSearch}
      options={renderSearchResultArray()}
      defaultActiveFirstOption={false}
      filterOption={false}
    />
  );
};

export default NotifierSearch;
